import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'


import roleplay from './images/roleplay.png'

function AboutUs() {
    return (
        <Container style={{ paddingTop: '100px', width: '70%' }} >
            <Row style={{ marginBottom: '60px' }}>
                <Col>
                    <h1 className='title-services'>
                        A PROPOS DE NOUS
                    </h1>
                </Col>
            </Row>
            <Row style={{ marginBottom: '60px' }}>
                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                    <h1 className='title-anniversaires'>
                        Les jeux d'imitations
                    </h1>
                    <div className='text-anniversaires'>
                        Babyccino est un lieu où les enfants peuvent jouer, socialiser, apprendre, se développer, imaginer et créer
                    </div>
                    <div style={{ textAlign: 'end' }}>
                        <Button className='button-concept' active outline size='lg' >
                            + D'INFOS
                        </Button>
                    </div>
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <img alt='playground' src={roleplay} width='80%' />
                </Col>
            </Row>
        </Container>
    );
}

export default AboutUs; 