import React from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Nav from './01.Header.js'
import Events from './04.Events'
import Concept from './05.Concept'
import Activities from './06.Activities';
import Coffee from './07.Coffee'
import FindUs from './08.FindUs.js'
import Email from './10.EmailConfirmation'


function App() {
    return (
        <div>
            <Nav />
            <Concept />
            <Events />
            <Activities />
            <Coffee />
            <Email />
            <FindUs />
        </div>
    );
}

export default App;
