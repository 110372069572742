import React from 'react'
import { Container, Row, Col} from 'reactstrap'

import bday1 from './images/bday1.png'
import bday2 from './images/bday2.png'
import bday3 from './images/bday3.png'
import girl from './images/Image.png'


function LinkBday() {
    return (
        <Container fluid >
            <Row className='birthday' style={{ marginBottom: '40px' }}>
            </Row>
            <Row className='offset-2' >
                <h1 className='title-FindUs'>
                    Party Package
                </h1>
            </Row>
            <Row xs='1' sm='1' md="3" lg='3' style={{ width:'80%',marginTop:'40px', margin:'auto' }}>
                <Col xs="12">
                    <div style={{ position: 'relative', top: '0', left: '0', display: 'flex', justifyContent: 'center' }}>
                        <img alt='shape' src={bday1} style={{ width: '70%', position: 'relative' }} />
                        <img alt='girl' src={girl} style={{ width: '80%', position: 'absolute', top: '5%' }} />
                    </div>
                </Col>
                <Col xs="12">
                    <div style={{ position: 'relative', top: '0', left: '0', display: 'flex', justifyContent: 'center' }}>
                        <img alt='shape' src={bday2} style={{ width: '70%', position: 'relative' }} />
                        <img alt='girl' src={girl} style={{ width: '80%', position: 'absolute', top: '5%' }} />
                    </div>
                </Col>
                <Col xs="12">
                    <div style={{ position: 'relative', top: '0', left: '0', display: 'flex', justifyContent: 'center' }}>
                        <img alt='shape' src={bday3} style={{ width: '70%', position: 'relative' }} />
                        <img alt='girl' src={girl} style={{ width: '80%', position: 'absolute', top: '5%' }} />
                    </div>
                </Col>

            </Row>
        </Container>
    )
}

export default LinkBday;