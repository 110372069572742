import React from 'react'
import { Container, Row, Col} from 'reactstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faPhoneAlt, faAt, faMapMarked, faMapPin } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import GoogleMapReact from 'google-map-react';

import logo from './images/logo.png'

const AnyReactComponent = ({ icon }) => <div><FontAwesomeIcon icon={faMapPin} size="2x" style={{ color: '#de4e4b' }} /></div>;

function Footer() {
    const defaultProps = {
        center: {
            lat: 48.835300,
            lng: 2.677010
        },
        zoom: 13
    };

    return (
        <Container fluid className='footerBg'>
            <Row >
                <Col xs={{ offset: 3 }} style={{ marginTop: '100px', width: '50%' }}>
                    <h3 style={{ fontFamily: 'Peace', color: '#F0ECE3', textShadow: '3px 3px #C0C9D1' }}>
                        Find Us
                    </h3>
                </Col>
            </Row>
            <Row xs='2' style={{ width: '50%', margin: 'auto', marginTop:'30px'  }}>
                <Col xs='6' style={{ height: '40vh'}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "" }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}>
                        <AnyReactComponent
                            lat={48.835300}
                            lng={2.677010}
                            text='We are here' />
                    </GoogleMapReact>
                </Col>
                <Col xs='6' style={{ alignContent: 'center', marginTop: '20px' }}>
                    <div className='text-findus'>
                        <FontAwesomeIcon icon={faMapMarked} size='2x' style={{ color: "#bac0d1" }} />
                        <div className='icon'>
                            <div style={{ fontFamily: 'Montserrat', fontWeight: 'bold', textDecoration: 'underline' }}>
                                Les Armoiries
                            </div>
                            <div style={{ fontFamily: 'Montserrat_reg' }}>
                                19, Boulevard Jean Monnet,
                                94360 Bry-sur-Marne,
                            </div>
                            <div style={{ fontFamily: 'Montserrat_reg' }}>
                                Shop X, Niveau 1
                            </div>
                        </div>

                    </div>
                    <div className='text-findus'>
                        <FontAwesomeIcon icon={faClock} size='2x' style={{ color: '#202a45' }} />
                        <div className='icon'>
                            <div style={{ fontFamily: 'Montserrat_reg' }}>Du Mardi au Dimanche</div>
                            <div style={{ fontFamily: 'Montserrat_reg' }}>9h30 - 18h30</div>
                        </div>
                    </div>
                    <div className='text-findus'>
                        <FontAwesomeIcon icon={faPhoneAlt} size='2x' style={{ color: '#c5cab3' }} />
                        <div className='icon' style={{ fontFamily: 'Montserrat_reg' }}>0123456789</div>
                    </div>
                    <div className='text-findus'>
                        <FontAwesomeIcon icon={faAt} size='2x' style={{ color: '#bf8977' }} />
                        <div className='icon' style={{ fontFamily: 'Montserrat_reg' }}>babyccino.familycafe@</div>
                    </div>
                </Col>
            </Row>
            <div style={{ position: 'relative',bottom:'95px', right: '40px', float:'right' }}>
                <FontAwesomeIcon icon={faFacebook} size='2x' style={{ marginRight: '5px' }} />
                <FontAwesomeIcon icon={faInstagram} size='2x' />
            </div>
            <Row style={{ width: '50%', margin: 'auto' }}>
                <Col style={{ marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    <Link style={{ color: 'black', textDecoration: 'none', fontFamily: 'Montserrat_reg' }} to='/Aproposdenous' >A propos de nous</Link>
                    <div>  | </div>
                    <Link style={{ color: 'black', textDecoration: 'none', fontFamily: 'Montserrat_reg' }} to='/Play' >Les Activités</Link>
                    <div>  | </div>
                    <Link style={{ color: 'black', textDecoration: 'none', fontFamily: 'Montserrat_reg' }} to='/Cafe' >L'Espace Café</Link>
                    <div>  | </div>
                    <Link style={{ color: 'black', textDecoration: 'none', fontFamily: 'Montserrat_reg' }} href='/Aproposdenous' >Contactez nous</Link>
                </Col>
            </Row>
            <Row xs='2'>
                <Col xs='3'>
                    <a href='/'>
                    <img alt="logo"c src={logo} width={200} style={{ marginLeft: '10px' }} />
                    </a>
                </Col>
                <Col xs='6' style={{ marginTop: '10px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItem: 'center' }}>
                        <Link style={{ color: 'black', textDecoration: 'none', fontFamily: 'Montserrat_reg', fontSize: '0.8em' }} href='/Aproposdenous' >T&C</Link>
                        <div style={{ marginLeft: '5px', marginRight: '5px', fontSize: '0.7em' }}>  | </div>
                        <Link style={{ color: 'black', textDecoration: 'none', fontFamily: 'Montserrat_reg', fontSize: '0.8em' }} href='/Aproposdenous' >Privacy Policy</Link>
                    </div>
                    <div style={{ textAlign: 'center', fontFamily: 'Montserrat_reg', fontSize: '0.8em' }}>
                        © 2023 Babyccino Family Cafe. All rights reserved.
                    </div>
                </Col>
            </Row>
            


        </Container>
    );
}

export default Footer;