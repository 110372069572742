import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import playground from './images/play.png'
import roleplay from './images/roleplay.png'
import birthday from './images/cake.png'


function Activities() {

    var settings = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: false,
        swipeToSlide: false,
        beforeChange: function (currentSlide, nextSlide) {
            console.log("before change", currentSlide, nextSlide);
        },
        afterChange: function (currentSlide) {
            console.log("after change", currentSlide);
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    className: 'center',
                    centerPadding: "105px",
                    centerMode: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,
                    dots: true,
                    vertical: false,
                    verticalSwiping: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    className: 'center',
                    centerPadding: "60px",
                    centerMode: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };


    return (
        <Container style={{ width: '70%', marginTop: '100px' }}>
            <Row style={{ marginBottom: '50px' }}>
                <Col>
                    <h1 className='title-services'>
                        LES ACTIVITES
                    </h1>
                </Col>
            </Row>
            <Slider {...settings}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ backgroundColor: 'red', width: '50%' }}>
                        <img alt='playground' src={playground} width='50%' />
                    </div>
                    <div>
                        <h1 className='title-playground'>
                            Playground
                        </h1>
                        <div className='text-playground'>
                            Babyccino est un lieu où les enfants peuvent jouer, socialiser, apprendre, se développer, imaginer et créer
                        </div>
                        <Button href='/Play' className='button-concept' active outline size='lg'>
                            + D'INFOS
                        </Button>
                    </div>
                </div>
                <Row style={{ marginBottom: '70px', display: 'flex', flexDirection: 'row' }}>
                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                        <h1 className='title-anniversaires'>
                            Les jeux d'imitations
                        </h1>
                        <div className='text-anniversaires'>
                            Babyccino est un lieu où les enfants peuvent jouer, socialiser, apprendre, se développer, imaginer et créer
                        </div>
                        <div style={{ textAlign: 'end' }}>
                            <Button href='/Play' className='button-concept' active outline size='lg' >
                                + D'INFOS
                            </Button>
                        </div>
                    </Col>
                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img alt='roleplay' src={roleplay} width='50%' />
                    </Col>
                </Row>
                <Row >
                    <Col>
                        <img alt='birthday' src={birthday} width='80%' />
                    </Col>
                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                        <h1 className='title-playground'>
                            Les Anniversaires
                        </h1>
                        <div className='text-playground'>
                            Babyccino est un lieu où les enfants peuvent jouer, socialiser, apprendre, se développer, imaginer et créer
                        </div>
                        <Button href='/Anniversaires' className='button-concept' active outline size='lg'>
                            + D'INFOS
                        </Button>
                    </Col>
                </Row>
            </Slider>
        </Container>
    )
}

export default Activities;