import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

import cafe from './images/coffee.png'

function Coffee() {

    return (
        <Container fluid style={{ width: '80%',marginTop: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Row style={{ width: '75%', marginBottom: '40px' }}>
                <Col>
                    <h1 className='title-gallery'>
                        L'ESPACE FAMILIAL
                    </h1>
                </Col>
            </Row>
            <Row xs='2' style={{ marginBottom: '70px', width: '80%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <Col xs='5' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height:'300px' }}>
                    <h1 className='title-anniversaires'>
                        L'espace Café
                    </h1>
                    <div className='text-anniversaires'>
                        Babyccino est un lieu où les enfants peuvent jouer, socialiser, apprendre, se développer, imaginer et créer
                    </div>
                    <div style={{ textAlign: 'end' }}>
                        <Button href='/Cafe' className='button-coffee' active outline size='lg' >
                            Voir le Menu
                        </Button>
                    </div>
                </Col>
                <Col xs='6' style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <img alt='playground' src={cafe} width='80%' />
                </Col>
            </Row>
        </Container>
    )
}

export default Coffee;