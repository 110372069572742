import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { Parallax } from 'react-scroll-parallax';
import { ParallaxProvider } from 'react-scroll-parallax';

import cloud from './images/cloud.png'

function Concept() {
    
    return (
        <div className='body-concept'>
            <Container fluid>
                <Row xs='1' sm='1' md="12" lg='12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                    <Col xs='12'>
                        <ParallaxProvider>
                            <Parallax speed={-22} style={{ width: '100%' }}>
                                <img alt='cloud' src={cloud} style={{ width: '50%', position: 'absolute', top: '30px', left: '50px' }} />
                            </Parallax>
                            <Parallax speed={-22} style={{ width: '100%' }}>
                                <img alt='cloud' src={cloud} style={{ width: '50%', position: 'absolute' }} />
                            </Parallax>
                            <Parallax speed={20}>
                            <img alt='cloud' src={cloud} style={{ width: '45%', position: 'absolute', left: '250px', top: '200px'}} />
                            </Parallax>
                        </ParallaxProvider>
                    </Col>
                    <Col xs='12' style={{ position:'absolute', top:'40%'}}>
                        <h1 className='title-concept'>
                            Babyccino Family Café
                        </h1>
                        <div style={{ fontFamily: 'Montserrat_reg',fontSize:'1.3em' }}>
                            Babyccino est un lieu où les enfants peuvent jouer, socialiser, apprendre, se développer, imaginer et créer
                        </div>
                        <Button href='/Aproposdenous' className='button-concept' active outline size='lg'>
                            + D'INFOS
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Concept;