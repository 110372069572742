import React from 'react'
import './App.css';
import { Button, Card, CardText, CardImg, Container, Row, Col } from 'reactstrap'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import girl from './images/Image.png'

function Events() {

    var settings = {
        className: 'center',
        centerPadding: "100px",
        centerMode: true,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    className: 'center',
                    centerPadding: "105px",
                    centerMode: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    className: 'center',
                    centerPadding: "60px",
                    centerMode: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };


    return (
        <Container style={{ marginTop: '120px', marginBottom: '120px' }}>
            <Row xs='1' sm='1' md="12" lg='12' style={{ display: 'flex', justifyContent: 'center' }}>
                <h1 xs='12' sm='12' md="12" lg='12' className='title-services'>
                    NOTRE ACTUALITE
                </h1>
            </Row>
            <Row style={{ marginTop: '50px' }}>
                <Slider {...settings}>
                    <div>
                        <Card className='card'>
                            <CardImg alt='girl' src={girl} className='card-img' />
                            <CardText className='cardText'>
                                C'est un endroit où les parents et les enfants peuvent s’épanouir et faire ressortir le meilleur d’eux-mêmes
                            </CardText>
                            <Button href='/Events' className='button-event' active outline size='lg'>
                                + D'INFOS
                            </Button>
                        </Card>
                    </div>
                    <div>
                        <Card className='card'>
                            <CardImg alt='girl' src={girl} className='card-img' />
                            <CardText className='cardText'>
                                C'est un endroit où les parents et les enfants peuvent s’épanouir et faire ressortir le meilleur d’eux-mêmes
                            </CardText>
                            <Button href='/Events' className='button-event' active outline size='lg'>
                                + D'INFOS
                            </Button>
                        </Card>
                    </div>
                    <div>
                        <Card className='card'>
                            <CardImg alt='girl' src={girl} className='card-img' />
                            <CardText className='cardText'>
                                C'est un endroit où les parents et les enfants peuvent s’épanouir et faire ressortir le meilleur d’eux-mêmes
                            </CardText>
                            <Button href='/Events' className='button-event' active outline size='lg'>
                                + D'INFOS
                            </Button>
                        </Card>
                    </div>
                </Slider>
            </Row>

        </Container>

    )
}

export default Events;