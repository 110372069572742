import React, { useState } from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, NavbarToggler, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Col, Row, Container } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

import logo from './images/logo.png'


function Header() {

  const [collapsed, setCollapsed] = useState(false);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div>
      <Navbar expand="md" light fixed='top' style={{ fontFamily: 'Montserrat_reg', fontSize: '1.1em', backgroundColor: '#F0ECE3' ,width:'100%'}}>
        <NavbarBrand href="/" >
          <img alt="logo" src={logo} width={260} height={66} style={{ marginLeft: '20px' }} />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} style={{marginRight:'10px'}}/>
        <Collapse isOpen={collapsed} navbar>
          <Nav navbar style={{  width:'100%',placeContent: 'end', textAlign: 'center' }} >
            <UncontrolledDropdown nav inNavbar >
              <DropdownToggle nav caret >
                Les Activités
              </DropdownToggle>
              <DropdownMenu style={{ textAlign: 'center' }} right>
                <DropdownItem href='/Play'>Le Playground</DropdownItem>
                <DropdownItem href='/Play'>Les Jeux d'imitations</DropdownItem>
                <DropdownItem href='/Anniversaires'>Les Anniversaires</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem style={{ textAlign: 'center' }}>
              <NavLink href="/components/">L'espace Café</NavLink>
            </NavItem>
            <NavItem style={{ textAlign: 'center' }}>
              <NavLink href="/Aproposdenous">A propos de nous</NavLink>
            </NavItem>
            <NavItem style={{ textAlign: 'center' }}>
              <NavLink href="/components/">Contactez nous</NavLink>
            </NavItem>
          </Nav>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center',marginRight:'20px'}}>
            <FontAwesomeIcon icon={faFacebook} size='2x' />
            <FontAwesomeIcon icon={faInstagram} size='2x' />
          </div>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Header;