import React from 'react'
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'

import RoundLogo from './images/Babyccino_Round_Logo.png'

function FindUs() {

    return (
        <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Row style={{ dislay: 'flex', width: '90%' }}>
                <Col style={{ marginBottom: '30px' }}>
                    <h1 className='title-FindUs'>CONTACTEZ NOUS !</h1>
                </Col>
            </Row>
            <Row xs='2' style={{ width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Col xs='12' md='12' lg='6'>
                    <p>Get in touch with our friendly team today for further advice and to get your project started. We have offices in the UK and the USA.</p>
                </Col>
                <Col xs='12' md='12' lg='6'>
                    <Form>
                        <Row xs='2'  >
                            <FormGroup style={{ marginBottom: '10px' }}>
                                <Label for="nom" style={{ fontFamily: 'Peace', fontSize: '1em', color: '#53566D' }}>
                                    Nom
                                </Label>
                                <Input
                                    id="nom"
                                    name="text"
                                    type="text"
                                />
                            </FormGroup>
                            <FormGroup style={{ marginBottom: '10px' }}>
                                <Label for="nom" style={{ fontFamily: 'Peace', fontSize: '1em', color: '#53566D' }}>
                                    Prénom
                                </Label>
                                <Input
                                    id="nom"
                                    name="text"
                                    type="text"
                                />
                            </FormGroup>
                        </Row>
                        <FormGroup style={{ marginBottom: '10px' }}>
                            <Label for="nom" style={{ fontFamily: 'Peace', fontSize: '1em', color: '#53566D' }}>
                                E-mail
                            </Label>
                            <Input
                                id="exampleEmail"
                                name="email"
                                type="email"
                            />
                        </FormGroup>
                        <FormGroup style={{ marginBottom: '10px' }}>
                            <Label for="nom" style={{ fontFamily: 'Peace', fontSize: '1em', color: '#53566D' }}>
                                Téléphone
                            </Label>
                            <Input
                                id="exampleNumber"
                                name="number"
                                type="text"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="nom" style={{ fontFamily: 'Peace', fontSize: '1em', color: '#53566D' }}>
                                Ton Message
                            </Label>
                            <Input
                                id="message"
                                name="text"
                                type="textarea"
                                style={{ height: '200px' }}
                            />
                        </FormGroup>
                        <FormGroup check style={{ marginTop: '10px' }}>
                            <Input type="checkbox" />
                            {' '}
                            <Label check style={{ fontFamily: 'Montserrat_reg', fontSize: '0.9em' }}>
                                Je souhaite m'inscrire à la newsletter
                            </Label>
                        </FormGroup>
                        <FormGroup check style={{ marginTop: '10px' }}>
                            <Input type="checkbox" />
                            {' '}
                            <Label check style={{ fontFamily: 'Montserrat_reg', fontSize: '0.9em' }}>
                                J'accepte les conditions d'utilisation
                            </Label>
                        </FormGroup>
                    </Form>
                    <Button className='button' style={{ width: '100px', backgroundColor: '#bf8977', border: '#bf8977', marginTop: '15px' }}>
                        Submit
                    </Button>
                </Col>
            </Row>
        </Container >
    )
}

export default FindUs;