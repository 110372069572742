import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

import playground from './images/play.png'

function LinkEvents() {
    return (
        <Container style={{ width: '70%', paddingTop: '100px' }}>
            <Row style={{ marginBottom: '60px' }}>
                <Col>
                    <h1 className='title-services'>
                        LES EVENEMENTS
                    </h1>
                </Col>
            </Row>
            <Row xs='2' style={{ marginBottom: '70px' }}>
                <Col>
                    <img className='img-services' alt='playground' src={playground} width='80%' />
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                    <h1 className='title-playground'>
                        Playground
                    </h1>
                    <div className='text-playground'>
                        Babyccino est un lieu où les enfants peuvent jouer, socialiser, apprendre, se développer, imaginer et créer
                    </div>
                    <Button className='button-concept' active outline size='lg'>
                        + D'INFOS
                    </Button>
                </Col>
            </Row>
            <Row xs='2' style={{ marginBottom: '70px' }}>
                <Col>
                    <img className='img-services' alt='playground' src={playground} width='80%' />
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                    <h1 className='title-playground'>
                        Playground
                    </h1>
                    <div className='text-playground'>
                        Babyccino est un lieu où les enfants peuvent jouer, socialiser, apprendre, se développer, imaginer et créer
                    </div>
                    <Button className='button-concept' active outline size='lg'>
                        + D'INFOS
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default LinkEvents;