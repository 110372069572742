import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import Play from './images/welcome.png'

function LinkCafe() {
    return (
        <Container fluid>
            <h2 style={{fontFamily:'Peace', position:'relative'}}>
                Play
            </h2>
            <img alt='play' src={Play} style={{ width: '100%', height: '400px' }} />
        </Container>
    )
}

export default LinkCafe;