import React from 'react'
import './App.css';
import { BrowserRouter as Router, Switch, Route, BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from "react-scroll-to-top";

import Main from './00.Main.js';
import Header from './01.Header.js'
import Footer from './02.Footer.js'
import Play from './11.Link-Play.js'
import Aproposdenous from './12.Link-AboutUs.js'
import Events from './13.Link-Events.js'
import Anniversaires from './14.Link-Anniversaires.js'
import Cafe from './15.Link-Cafe.js'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <div className='body'>
          <Route exact path="/" component={Main} />
          <Route path="/Aproposdenous" component={Aproposdenous} />
          <Route path="/Events" component={Events} />
          <Route path="/Play" component={Play} />
          <Route path="/Anniversaires" component={Anniversaires} />
          <Route path="/Cafe" component={Cafe} />
          <Header />
          <Footer />
          <ScrollToTop smooth />
        </div>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
