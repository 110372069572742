import React from 'react'
import { Container, Row, Col, Button, Form, FormGroup, Input } from 'reactstrap'

function EmailConfirmation() {
    return (
            <Container fluid style={{ background: '#F1DBC6', height:'200px', display: 'flex',flexDirection:'row', justifyContent: 'center',alignItems:'center',marginBottom:'100px' }}>
                <Row xs='2' style={{ display: 'flex',flexDirection:'row',justifyContent:'center', alignItems: 'center', width:'850%' }}>
                    <Col xs='12' md='6' l='4' xl='4' >
                        <h2 style={{fontFamily:'Peace', color:'#53566D'}}>
                            Rejoignez notre Famille
                        </h2>   
                        <div style={{fontFamily:'Montserrat_reg', color:'#53566D', fontSize:'1em'}}>
                            pour recevoir nos dernières actualités et nos offres exclusives !
                        </div>
                    </Col>
                    <Col xs='12' md='6' l='4' xl='4' style={{ display: 'flex',flexDirection:'row', justifyContent: 'space-around', alignItems: 'center' }}>
                        <Form style={{width:'70%'}}>
                            <FormGroup>
                                <Input
                                    id="exampleEmail"
                                    name="email"
                                    placeholder="E-mail"
                                    type="email"
                                />
                            </FormGroup>
                        </Form>
                        <Button className='button-event' active outline size='md'>
                            VALIDER
                        </Button>
                    </Col>
                </Row>
            </Container>
    )
}

export default EmailConfirmation;